import React from 'react';
import CV from '../../assets/CV_William_Rayhan_Harsono.pdf';

const CTA = () => {
  return (
    <div className="cta" >
      <a href={CV} download className="btn" data-aos="fade-right" data-aos-duration="2000" data-aos-delay="500">
        Download CV
      </a>
      <a href="#contact" className="btn btn-primary" data-aos="fade-right" data-aos-duration="2000">
        Let's talk
      </a>
    </div>
  );
};

export default CTA;