import React from 'react';
import IMG1 from '../../assets/puskesmaskelapadua.jpg';
import IMG2 from '../../assets/penelitian.jpg';
import IMG3 from '../../assets/infotiket.png';

import './portfolio.css';

const Portfolio = () => {
  const soloProjects = [
    {
      id: 1,
      title: 'Information System Puskesmas Kelapa Dua Tangerang',
      img: IMG1,
      description:
        "Led and designed a website for Kelapa Dua Health Center (Puskesmas) over a period of 3 months.",
      technologies: 'Product Manager | Frontend Developer',
      link: 'https://puskesmaskelapadua.com/',
    },
    {
      id: 2,
      title: "Men's Perfume Selection System with AHP and TOPSIS Algorithm Research",
      img: IMG2,
      description:
        'Led a team of 4 talented individuals in a research project titled "Sistem Rancang Bangun Pemilihan Parfum Pria dengan Algoritma AHP dan TOPSIS."',
      technologies: 'Product Manager',
      link: 'https://pemilihanparfumkekinian.com/',
      citation: 'https://ijstm.inarah.co.id/index.php/ijstm/article/view/1002',
    },
    {
      id: 3,
      title: "InfoTiket.in - Kampus Merdeka Final Project",
      img: IMG3,
      description:
        "Completed the Kampus Merdeka final project as a Front End Developer at Binar Academy. The final project is named 'InfoTiket.in'.",
      technologies: 'Frontend Developer',
      link: 'https://infotiket.in/',
    },
  ];

  return (
    <section id="portfolio">
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        {soloProjects.map((pro) => (
          <article className="portfolio__item" key={pro.id}>
            <div className="portfolio__item-image">
              <img src={pro.img} alt={pro.title} />
            </div>
            <div className="portfolio__item-content">
              <h3>{pro.title}</h3>
              <p>{pro.description}</p>
              <p>{pro.technologies}</p>
            </div>
            <div className="portfolio__item-cta">
              <a
                href={pro.link}
                target="_blank"
                className="btn btn-primary"
                rel="noreferrer"
              >
                Visit Website
              </a>
              {pro.citation && (
                <a
                  href={pro.citation}
                  target="_blank"
                  className="btn btn-secondary"
                  rel="noreferrer"
                >
                  Publication
                </a>
              )}
            </div>
          </article>
        ))}
      </div>
    </section>
  );
};


export default Portfolio;
