import React from 'react';
import { FaAward } from 'react-icons/fa';
import { VscFolderLibrary } from 'react-icons/vsc';
import { FaJournalWhills } from "react-icons/fa";
import { HiOfficeBuilding } from "react-icons/hi";
import ME from '../../assets/me.jpg';
import './intro.css';

const Intro = () => {
  return (
    <section id="about">
      <h5 data-aos="fade-up"
     data-aos-anchor-placement="bottom-center" data-aos-duration="500">Get to know</h5>
      <h2 data-aos="fade-up"
     data-aos-anchor-placement="bottom-center" data-aos-duration="500">About Me</h2>
      <div className="container about__container">
        <div className="about__me" data-aos="fade-up"
     data-aos-anchor-placement="bottom-bottom" data-aos-duration="1000">
          <div className="about__me-image">
            <img data-aos="fade-up"
     data-aos-anchor-placement="bottom-bottom" data-aos-duration="500" data-aos-delay="500" src={ME} alt="me" />
          </div>
        </div>
        <div className="about__content" >
          <div className="about__cards" data-aos="fade-up"
     data-aos-anchor-placement="bottom-bottom" data-aos-duration="2000">
            <article className="about__card">
              <FaAward className="about__icon" />
              <h5>Experience</h5>
              <small>2 Year</small>
            </article>
            <article className="about__card">
              <VscFolderLibrary className="about__icon" />
              <h5>Projects</h5>
              <small>3 Completed</small>
            </article>
            <article className="about__card">
              <FaJournalWhills className="about__icon" />
              <h5>Publication</h5>
              <small>1 International</small>
            </article>
            <article className="about__card">
              <HiOfficeBuilding className="about__icon" />
              <h5>Company</h5>
              <small>PT. Infracom Technology</small>
            </article>
          </div>
          
          <p>My name is William Rayhan Harsono. I am currently working as a Cloud & System Integration Engineer Intern at PT. Infracom Technology (ICT). My current focus is on developing skills in Cloud technology. I am known for being diligent, adaptable, and highly motivated. My enthusiasm for learning ensures valuable contributions in every role I undertake.</p>
          <a href="#contact" className="btn btn-primary">Let's Talk</a>
        </div>
      </div>
    </section>
  )
}
//https://media.licdn.com/dms/image/C560BAQFhpP5YvCD3gw/company-logo_200_200/0/1658457428989/pt_infracom_technology_logo?e=2147483647&v=beta&t=2x2RoeUv-HJfHGKZNKYQxtXSlXVOqeO2H0Ug3Br3S_4
export default Intro