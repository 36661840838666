import React from 'react';
import Contact from './components/contact/Contact.jsx';
import Footer from './components/footer/Footer.jsx';
import Header from './components/header/Header.jsx';
import Intro from './components/intro/Intro.jsx';
import Portfolio from './components/portfolio/Portfolio.jsx';
import Topbar from './components/topbar/Topbar.jsx';
// import Timeline from './components/timeline/Timeline.jsx';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';

const App = () => {
  
  useEffect(() => {
    AOS.init({
      once:true,
    })
  }, [])

  return (
    <>
      <Header />
      <Topbar />
      <Intro />
      <Portfolio />
      {/* <Timeline /> */}
      <Contact />
      <Footer />
    </>
  )
}

export default App
