import React from 'react';
import CTA from './CTA';
import HeaderSocials from './HeaderSocials';
import './header.css';

const Header = () => {
  return (
    <header id="home">
      <div className="container header__container">
        <h1 data-aos="fade-right" data-aos-duration="1000">Hi I'm William. Glad to see you.</h1>
        <h5 data-aos="fade-right" data-aos-duration="1000" className="text-light">A highly skilled Computer Science student at Multimedia Nusantara University, demonstrating a strong passion for continuous learning.
                <p data-aos="fade-right" data-aos-duration="1000">Interested in Website Development and Cloud Computing.</p>
                <p></p>
                </h5>
        <CTA />
        <a href="#contact" className="scroll__down">
          Scroll Down
        </a>
        <HeaderSocials />
      </div>
    </header>
  );
};

export default Header;
